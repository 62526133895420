import './App.css';
import React, { useState } from 'react'
import Header from './components/Header';
import Intro from './components/Intro';
import EatLocal from './components/EatLocal'
import { Switch, Route } from 'react-router-dom';
import Around from './components/Around';
import Soupwarts from './components/Soupwarts';
import HistoryClass from './components/HistoryClass';
import More from './components/More';
import CanIBike from './components/CanIBike';

const App = () => {
const [ allDark, setAllDark ] = useState(false)
const nameMode = allDark ? 'darkName' : 'name'
const headerMode = allDark ? 'darkHeader' : 'header'
const menuMode = allDark ? 'darkMenu' : 'menu-button'
const linkMode = allDark ? 'darkLinks' : 'link'
const buttonMode = allDark ? 'darkButton' : 'lightButton'
const bioMode = allDark ? 'darkBio' : 'bio'
const bioLinkMode = allDark ? 'darkBioLink' : 'linkBio'
const aboutMode = allDark ? 'aboutDark' : 'more-stuff'
const descMode = allDark ? 'descDark' : 'desc'



const handleSwitch = () => {
  setAllDark(!allDark);
}

  return (
    <div style={{ backgroundColor: allDark ? "rgb(41, 17, 82)" : "rgb(230, 242, 252)",  paddingBottom: '100vh'}}>
     <Header handleSwitch={handleSwitch} 
             buttonMode={buttonMode} 
             nameMode={nameMode} 
             headerMode={headerMode}
             menuMode={menuMode}/>
     <Switch>
      <Route exact path='/'><Intro bioMode={bioMode} bioLinkMode={bioLinkMode}/></Route>
      <Route exact path='/Around'><Around linkMode={linkMode} descMode={descMode}/></Route>
      <Route exact path='/Can-I-Bike'><CanIBike linkMode={linkMode} descMode={descMode}/></Route>
      <Route exact path='/Soupwarts'><Soupwarts linkMode={linkMode} descMode={descMode}/></Route>
      <Route exact path='/History-Class'><HistoryClass linkMode={linkMode} descMode={descMode}/></Route>
      <Route exact path='/Eat-Local'><EatLocal linkMode={linkMode} descMode={descMode}/></Route>
      <Route exact path='/About-Cole'><More aboutMode={aboutMode} allDark={allDark}/></Route>
     </Switch>
    </div>
  )
}

export default App