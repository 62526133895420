import React from 'react'
import './project-page.css';
import photo1 from "../assets/history-class-photos/photo1.png"
import photo2 from "../assets/history-class-photos/photo2.png"
import photo3 from "../assets/history-class-photos/photo3.png"

const HistoryClass = ({linkMode}) => {
  console.log(linkMode)
  return (
    <div className='project'>
      <div className='links'>
      <a className={linkMode} href='https://history-class-showcase.vercel.app/' target="_blank" rel="noopener noreferrer">History Class Website</a>
      <a className={linkMode} href='https://github.com/coleanthony1990/history-class' target="_blank" rel="noopener noreferrer">Github</a>
      </div>
      <div className='photo-container'>
      <img className='screen-shots' src={photo1} alt='eat local website'/>
      <img className='screen-shots' src={photo2} alt='eat local website'/>
      <img className='screen-shots' src={photo3} alt='eat local website'/>
      
      </div>
    </div>
  )
}

export default HistoryClass
