import React from 'react'
import { BsMoon, BsSun } from 'react-icons/bs'

const DarkMode = ({handleSwitch, buttonMode}) => {
  const nightDay = buttonMode === 'darkButton' ? <BsSun/> : <BsMoon/>
 
const switchinTime = () => {
  handleSwitch()
}
  
  return (
    <div>
     <button onClick={switchinTime} className={buttonMode}>{nightDay}</button>
    </div>
  )
}

export default DarkMode
