import React from 'react'
import video1 from "../assets/bike-photos/video1.mp4"

const CanIBike = ({linkMode, descMode}) => {
  return (
    <div className='project'>
      <div className='links'>
      <a className={linkMode} href='https://can-i-bike.vercel.app/' target="_blank" rel="noopener noreferrer">Can I Bike? Website</a>
      <a className={linkMode} href='https://github.com/coleanthony1990/Can-i-bike-' target="_blank" rel="noopener noreferrer">Github</a>
      </div>
      <p className={descMode} style={{textAlign: 'center'}}>This project is still in progress, so don't judge the styling but showcases some data visualization using Victory.</p>
      <div className='photo-container'>
      <video controls loop className='screen-shots'><source src={video1} type='video/mp4'></source></video>
      
      
      </div>
    </div>
  )
}

export default CanIBike
