import React, { useState } from 'react'
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";

import { NavLink, useHistory } from 'react-router-dom';

const List = ({menuMode, buttonMode}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory()
  
  
  const handleClose = (event) => {
    event.preventDefault()
    setAnchorEl(null);
    history.push(`/${event.target.textContent.replace(' ', '-')}`)
  };
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /*eslint-disable*/
  return (
    <div className='projects-box'>
      
      
      <div className={menuMode}>
      <NavLink to='/About-Cole' style={{textDecoration: 'none', color: menuMode === 'menu-button' ? 'black' : 'white'}} className='about-me-link'>ABOUT ME</NavLink>
      <Button
        name='menu-button'
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
      <a className={menuMode} style={{marginLeft: '15px'}}>Projects</a>
      </Button>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        
      >
        <MenuItem style={{fontFamily:'Belleza', fontWeight: '500'}} onClick={handleClose}>Eat Local</MenuItem>
        <MenuItem style={{fontFamily:'Belleza', fontWeight: '500'}} onClick={handleClose}>Can-I-Bike</MenuItem>
        <MenuItem style={{fontFamily:'Belleza', fontWeight: '500'}} onClick={handleClose}>History Class</MenuItem>
        <MenuItem style={{fontFamily:'Belleza', fontWeight: '500'}} onClick={handleClose}>Soupwarts</MenuItem>
        <MenuItem style={{fontFamily:'Belleza', fontWeight: '500'}} onClick={handleClose}>Around</MenuItem>
      </Menu>
      </div>
    </div>
  );
};

export default List
