import React from 'react';
import './project-page.css';
import photo1 from "../assets/eat-local-photos/photo1.png"
import photo2 from "../assets/eat-local-photos/photo2.png"
import photo3 from "../assets/eat-local-photos/photo3.png"
import photo4 from "../assets/eat-local-photos/photo4.png"
import photo5 from "../assets/eat-local-photos/photo5.png"
import photo6 from "../assets/eat-local-photos/photo6.png"

const EatLocal = ({linkMode, descMode}) => {

  return (
    <div className='project'>
      <div className='links'>
      <a className={linkMode} href='https://eatlocal.vercel.app/' target="_blank" rel="noopener noreferrer">Eat Local Website</a>
      <a className={linkMode} href='https://github.com/Eat-Local/eat-local-fe' target="_blank" rel="noopener noreferrer">Github</a>
      </div>
      <p className={descMode} style={{textAlign: 'center'}}>This project was built in collaboration with a backend team using Ruby on Rails.</p>
      <div className='photo-container'>
      <img className='screen-shots' src={photo1} alt='eat local website'/>
      <img className='screen-shots' src={photo2} alt='eat local website'/>
      <img className='screen-shots' src={photo3} alt='eat local website'/>
      <img className='screen-shots' src={photo4} alt='eat local website'/>
      <img className='screen-shots' src={photo5} alt='eat local website'/>
      <img className='screen-shots' src={photo6} alt='eat local website'/>
      </div>
    </div>
  )
}

export default EatLocal
