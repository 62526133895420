import React from 'react'
import List from './List'
import "./Header.css"
import { Link } from 'react-router-dom'

import DarkMode from './DarkMode'



const Header = ({handleSwitch, nameMode, headerMode, menuMode, buttonMode}) => {
  return (
    <div className={headerMode}>
      <div className='name-menu'>
      <Link to='/' style={{ textDecoration: 'none'}}>
      <h1 className={nameMode}>Cole Anthony</h1>
      </Link>
      <List menuMode={menuMode} handleSwitch={handleSwitch} buttonMode={buttonMode}/>
      </div>
      <DarkMode handleSwitch={handleSwitch} buttonMode={buttonMode}/>
    </div>
  )
}

export default Header
