import React from 'react'
import './project-page.css';
import photo1 from "../assets/soupwarts-photos/photo1.png"
import photo2 from "../assets/soupwarts-photos/photo2.png"
import photo3 from "../assets/soupwarts-photos/photo3.png"

const Soupwarts = ({linkMode, descMode}) => {
  return (
    <div>
      <div className='links'>
      <a className={linkMode} href='https://coleanthony1990.github.io/soupwarts/#/' target="_blank" rel="noopener noreferrer">Soupwarts Website</a>
      <a className={linkMode} href='https://github.com/coleanthony1990/soupwarts' target="_blank" rel="noopener noreferrer">Github</a>
      </div>
      <p className={descMode} style={{textAlign: 'center'}}>This project was built with React and Typescript.</p>
      <div className='photo-container'>
      <img className='screen-shots' src={photo3} alt='Soupwarts Website'/>
      <img className='screen-shots' src={photo1} alt='Soupwarts Website'/>
      <img className='screen-shots' src={photo2} alt='Soupwarts Website'/>
      </div>
    </div>
  )
}

export default Soupwarts
