import React from 'react'
import './about.css'


const More = ({aboutMode, allDark}) => {

  const skillsMode = allDark ? 'darkSkills' : 'skills'
  const schoolMode = allDark ? 'darkSchool' : 'school'
  const uxMode = allDark ? 'darkUX' : 'UX'

  return (
    <div className={aboutMode}>
      <div className={uxMode}>
        <p>Specializing as a frontend developer, I am skilled in combining design and technical expertise to create visually appealing and user-friendly digital products. I have a strong understanding of user-centered design principles and am capable of designing interfaces that provide an intuitive and engaging user experience. I am proficient in using various design tools and technologies to create wireframes, prototypes, and visual designs. I have combined my experience with front-end development and am capable of implementing designs using HTML, CSS, JavaScript as well as many other useful technologies. I am a detail-oriented, problem solver and have a passion for creating products that users will love.</p>
      </div>
      <div className='abilities'>
        
        <ul className={skillsMode}>
        <h3>Things I'm good at</h3>
          <li className='tech'>Javascript</li>
          <li className='tech'>Typescript</li>
          <li className='tech'>React</li>
          <li className='tech'>HTML5</li>
          <li className='tech'>CSS3</li>
          <li className='tech'>Python</li>
          <li className='tech'>Django</li>
          <li className='tech'>Svelte</li>
          <li className='tech'>Cypress</li>
          <li className='tech'>GraphQL</li>
          <li className='tech'>Swift</li>
          <li className='tech'>Victory</li>
          <li className='tech'>etc...</li>
        </ul>
        <ul className={schoolMode}>
          <h3>Education</h3>
          <li>Turing School of Software & Design</li>
          <li>Metropolitan State University of Denver</li>
          <li>National Outdoor Leadership School</li>
        </ul>
      </div>
      
    </div>
  )
}

export default More
