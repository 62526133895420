import React from "react";
import pic from "../assets/me/pro-pic.jpeg";
import { Link } from "react-router-dom";

const Intro = ({ bioMode, bioLinkMode }) => {
  return (
    <div className="intro">
      <p className={bioMode}>
        Hi there! Im a software developer specializing in modern frontend web
        development using Javascript, React, Typescript, CSS, etc. Reach me at{" "}
        <a
          style={{
            textDecoration: "none",
            color: bioMode === "darkBio" ? "skyblue" : "royalblue",
          }}
          href="mailto: cole.edwin.anthony@gmail.com"
        >
          cole.edwin.anthony@gmail.com
        </a>
      </p>{" "}
      <p className={bioLinkMode}>
        Click{" "}
        <Link
          to="/about-cole"
          style={{
            textDecoration: "none",
            color: bioMode === "darkBio" ? "skyblue" : "royalblue",
          }}
        >
          HERE{" "}
        </Link>
        for more info about me or check out some of my projects in the menu.
      </p>
      <img className="me-pic" src={pic} alt="Cole Anthony" />
    </div>
  );
};

export default Intro;
