import React from 'react'
import './project-page.css';
import photo1 from "../assets/around-photos/photo1.png"
import photo2 from "../assets/around-photos/photo2.png"
import photo3 from "../assets/around-photos/photo3.png"
import photo4 from "../assets/around-photos/photo4.png"

const Around = ({linkMode, descMode}) => {
  return (
    <div>
     <div className='links'>
      <a className={linkMode} href='https://github.com/coleanthony1990/Travel-Tracker' target="_blank" rel="noopener noreferrer">Github</a>
      </div>
      <div className='photo-container'>
      <p className={descMode}>This was a full on vanilla JS application. My last one before learning react. The data was all being organized through JS classes and then manipulated before being displayed the the DOM. It was fun and I think a decent example of what I can do with vanilla JS.</p>
      <img className='screen-shots' src={photo1} alt='Around Website'/>
      <img className='screen-shots' src={photo2} alt='Around Website'/>
      <img className='screen-shots' src={photo3} alt='Around Website'/>
      <img className='screen-shots' src={photo4} alt='Around Website'/>
      
      </div>
    </div>
  )
}

export default Around
